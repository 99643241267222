html {
	margin: 0;
	padding: 0;
}

body {
	margin: 0;
	padding: 0;
	background: #000;
}

@media (min-width: 768px) {
	*::-webkit-scrollbar {
		width: 16px;
	}

	*::-webkit-scrollbar-track {
		background: #000000;
	}

	*::-webkit-scrollbar-thumb {
		background-color: #afa;
		border-radius: 10px;
		border: 6px solid #000000;
	}
}

#background {
	position: absolute;
	z-index: -1;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	overflow: hidden;
}

.dot {
	background: green;
	width: 4px;
	height: 4px;
	border-radius: 50%;
	position: absolute;
}

.pinkDot {
	background: #ee71c3;
	width: 2px;
	height: 2px;
	position: absolute;
	right: 30px;
	bottom: 35px;
}

#canvas {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: calc(100vh - 20px);
	width: calc(100vw - 20px);
	padding: 10px;
	overflow: hidden;
	position: relative;
	word-wrap: break-word;
	@media (max-width: 767px) {
		align-items: flex-start;
		justify-content: flex-start;
	}
	&.projects {
		align-items: flex-start;
		justify-content: flex-start;
		overflow-y: auto;
	}
	&,
	a,
	a:visited,
	a:active {
		font-family: monospace;
		font-size: 20pt;
		color: #afa;
		text-decoration: none;
	}
	a:hover,
	a:focus {
		color: #000;
		background: #afa;
		outline: unset;
	}
	&.main a {
		height: 35px;
		display: inline-block;
	}
}
.cursor-space {
	position: relative;
}
.cursor {
	position: absolute;
	right: -10px;
	top: 0px;
	@media (max-width: 767px) {
		visibility: hidden;
	}
}
.link-cover {
	position: absolute;
	background: #afa;
}
.link-cover-second {
	position: absolute;
	background: #000;
}

#timeline {
	width: 50%;
	margin-top: 40px;
	position: relative;
	@media (max-width: 767px) {
		width: 100%;
		margin-top: 20px;
	}
	& > div {
		display: none;
	}

	&.loading {
		&:after {
			content: '';
			position: absolute;
			top: -1px;
			left: 8px;
			width: 8px;
			height: 10px;
			background: #000;
		}
		&:before {
			content: '';
			position: absolute;
			top: 6px;
			left: 4px;
			width: 8px;
			height: 8px;
			border-radius: 50%;
			border: 4px solid #afa;
			background: #000;
			z-index: 1;
		}
	}
}

.item-year,
.item-header,
.item-content {
	position: relative;
	min-height: 20px;
	padding-left: 24px;
	font-size: 16px;
	&:before {
		content: '';
		position: absolute;
		top: 0px;
		left: 10px;
		width: 4px;
		height: 100%;
		background: #afa;
	}
	&:first-child:before {
		top: 50%;
		height: 50%;
	}
	&:last-child:before {
		height: 10px;
	}
}

#canvas .item-content {
	& a,
	& a:visited,
	& a:active{
		font-size: 16px;
	}
}

.item-header {
	&:after {
		content: '';
		position: absolute;
		top: 5px;
		left: 6px;
		width: 12px;
		height: 12px;
		border-radius: 50%;
		background: #afa;
	}
}

.item-year {
	font-size: 24px;
	&:after {
		content: '';
		position: absolute;
		top: 50%;
		left: 4px;
		width: 8px;
		height: 8px;
		border-radius: 50%;
		transform: translateY(-50%);
		border: 4px solid #afa;
		background: #000;
	}
}
